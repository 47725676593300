<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="테이블 목록"
          tableId="table"
          selection="multiple"
          rowKey="tableId"
          :columns="grid.columns"
          :data="grid.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- <c-btn label="QR코드 출력" :editable="editable" icon="save_alt" @btnClicked="btnQrCode" /> -->
              <c-btn label="전체 테이블정의서 다운로드" icon="save_alt" color="red" @btnClicked="printTableAll" />
              <c-btn label="선택 테이블정의서 다운로드" icon="save_alt" @btnClicked="printTable" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'db-erd',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'tableId',
            field: 'tableId',
            label: '테이블명 (영문)',
            align: 'left',
            sortable: true,
            style: 'width: 360px',
          },
          {
            name: 'tableNameOri',
            field: 'tableNameOri',
            label: '테이블명 (한글)',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      erdAllUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.devtool.dbtables.url;
      this.erdAllUrl = selectConfig.devtool.erd.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    printTableAll() {
      let thisVue = this;
      this.$http.url = this.erdAllUrl;
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '테이블정의서.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    printTable() {
      let thisVue = this;
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.erdAllUrl + '/select';
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData)
        }
        this.$http.request(
          _result => {
            let fileOrgNm = '테이블정의서.xlsx';
            let blob = thisVue.$comm.base64ToBlob(_result.data);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
            } else {
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = fileOrgNm;
              link.click();
            }
          }, () => { }
        );
      }
    },
  }
};
</script>
